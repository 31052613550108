window.addEventListener('load', function(){
    const catSelects = document.querySelectorAll(".select_item.cat_select .item_inner") as NodeListOf<HTMLDivElement>;
    const tagSelects = document.querySelectorAll(".select_item.tag_select .item_inner") as NodeListOf<HTMLDivElement>;
    const searchBtn = document.querySelector(".search_btn_area .search_btn") as HTMLAnchorElement;
    const searchTitles = document.querySelectorAll(".search_main_box .search_item_ttl") as NodeListOf<HTMLDivElement>;
    const pageContainer = document.querySelector(".container");
    let searchContItemHeight:number;
    let searchContItemHeightUnit:string;

    if(isSmartPhone()){
        searchContItemHeight = 14.6666666667;
        searchContItemHeightUnit = "vw";
    }else{
        searchContItemHeight = 90;
        searchContItemHeightUnit = "px";
    }

    let catObj = {
        slug: "",
        selected: false,
    };
    let tagObj = {
        id: "",
        selected: false,
    };

    // 各要素にクリックイベントを割り当てる
    catSelects.forEach(function (catSelect) {
        catSelect.addEventListener("click", function () {
            updateSearchTitleTxt(catSelect)

            catObj.slug = catSelect.dataset.catSlug || "";
            catObj.selected = true;
            updateSearchBtn();

            catSelects.forEach(function (select) {
                select.classList.remove("active")
            });
            catSelect.classList.add("active")
        });
    });

    tagSelects.forEach(function (tagSelect) {
        tagSelect.addEventListener("click", function () {
            updateSearchTitleTxt(tagSelect)
            
            tagObj.id = tagSelect.dataset.tagId || "";
            tagObj.selected = true;
            updateSearchBtn();

            tagSelects.forEach(function (select) {
                select.classList.remove("active")
            });
            tagSelect.classList.add("active")
        });
    });

    searchTitles.forEach(function (title) {
        title.addEventListener("click", function () {
            title.classList.toggle("active");
            
            const searchCont = title.nextElementSibling as HTMLDivElement;
            if (!searchCont) { return; }
            
            let searchContHeight:number = 0;
            const searchContItems = searchCont.children;

            if(searchCont.classList.contains("active")){
                searchCont.classList.remove("active");

                for (let i = 0; i < searchContItems.length; i++) {
                    const searchContItem = searchContItems[i] as HTMLElement;
                    searchContItem.style.height = '0';
                }
                searchCont.style.height = '0';

            }else{
                searchCont.classList.add("active");

                for (let i = 0; i < searchContItems.length; i++) {
                    const searchContItem = searchContItems[i] as HTMLElement;
                    searchContItem.style.height = `${searchContItemHeight}${searchContItemHeightUnit}`
                    searchContHeight += searchContItemHeight;
                }
                searchCont.style.height = `${searchContHeight}${searchContItemHeightUnit}`
            }

        });
    });
    

    function updateSearchTitleTxt(dom:any) {
        const selectItemTxt:any = dom.textContent;
        const selectItemTtlDom:any = dom.closest('.layout_item').firstElementChild.querySelector(".ttl_inner");
        selectItemTtlDom.textContent = selectItemTxt;
    }
    
    clickPageContainer()

    function clickPageContainer() {
        let closeSearchEnabled: boolean;
        closeSearchEnabled = false;
        let activeSearchCount:number = 0;

        searchTitles.forEach(function (title) {
            
            pageContainer.addEventListener('click', (event) => {
                
                const targetNode = event.target as Node;
                let isClickedInside = title.contains(targetNode);
                
                closeSearchEnabled = title.classList.contains("active");

                if (closeSearchEnabled && !isClickedInside) {
                    title.click();
                }

                
                // if(activeSearchCount >= 1){ 
                        
                //     if (!isClickedInside) {
                //         console.log(activeSearchCount);
                //         console.log('要素の外側をクリックしました');
                //     }
                //     return; 
                // }

                // if(closeSearchEnabled){ 
                //     activeSearchCount++; 
                // }else{
                //     activeSearchCount = 0; 
                // }

            });
        });
    }

    function isSmartPhone() {
        if (window.matchMedia && window.matchMedia('(max-device-width: 768px)').matches) {
            return true;
        } else {
            return false;
        }
    }

    function updateSearchBtn() {
        if(!(catObj.selected && tagObj.selected)){
            return;
        }

        searchBtn.href = `${catObj.slug}?tabId=${tagObj.id}#region_id_${tagObj.id}`;
        searchBtn.classList.add("active");
    }
    
});
